import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import { transactionAPI } from '../../../../utils/apiService';
import { getCookie } from '../../../../utils/cookieService';
import { logSharedResponse, handleErrorMessage } from '../../../../utils';

const MfSmallcasesForm = props => {
  const { getFieldDecorator } = props.form;
  const placeOrder = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      transactionAPI(
        getCookie('userID'),
        'TRANSACTION',
        {
          assetUniverse: 'MUTUAL_FUND',
          type: values['orderType'],
          scid: values['scid'],
          iscid: values['iscid'],
        },
        null,
      )
        .then(({ transactionId }) => {
          return transactionId;
        })
        .then(transactionId =>
          window.sc.triggerTransaction({
            transactionId,
          }),
        )
        .then(res => {
          logSharedResponse('Order Response', res);
        })
        .catch(err => {
          handleErrorMessage(err);
          console.log(err);
        });
    });
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  return (
    <div className="container">
      <Card
        title="MF Smallcases"
        bodyStyle={{
          padding: '12px',
        }}
      >
        <Row>
          <Col span={24}>
            <Form {...formItemLayout} onSubmit={placeOrder}>
              <Form.Item label="SCID">
                {getFieldDecorator('scid', {
                  rules: [{ required: true, message: 'Please input SCID!' }],
                })(<Input placeholder="SCID" />)}
              </Form.Item>
              <Form.Item label="ISCID">
                {getFieldDecorator('iscid', {
                  rules: [],
                })(<Input placeholder="ISCID" />)}
              </Form.Item>
              <Form.Item label="Order Type" hasFeedback>
                {getFieldDecorator('orderType', {
                  rules: [
                    { required: true, message: 'Please select order type!' },
                  ],
                })(
                  <Select>
                    <Select.Option value="BUY">BUY</Select.Option>
                    <Select.Option value="INVESTMORE">INVESTMORE</Select.Option>
                    <Select.Option value="SELL">SELL</Select.Option>
                  </Select>,
                )}
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 8 },
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const MfSmallcases = Form.create({ name: 'mf_smallcases' })(MfSmallcasesForm);

export default MfSmallcases;
